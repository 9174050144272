import Navbar from "../components/navbar";
import Footer from "../components/footer";

export default function NotFound(){
    return(
        <div class="main-container">
            <Navbar />
            <h1>404: Not Found</h1>
            <Footer />
        </div>
        
    )
    
}